<template>
  <div class="directorship">
    <Loader v-if="isLoading" />

    <div class="container" v-if="!isLoading">
      <div class="h4">Руководство</div>
    </div>
    <div class="directors-swiper swiper-container" v-if="!isLoading">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="(director, i) in directorsList" :key="i">
          <img :src="getPreview(director.preview)" />
        </div>
      </div>
    </div>
    <Pagination :swiper="directorsSwiper" :text="currentDirector.name" v-if="!isLoading" />
    <p class="directorship__position" v-if="!isLoading">
      {{ currentDirector.position }}
    </p>
    <div class="container directorship__info" v-if="!isLoading">
      <div class="row">
        <div class="col-7 directorship__content marked-text" v-html="marked(currentDirector.content)"></div>
        <div class="col-5 col-pl directorship__image">
          <div class="photo-swiper swiper-container">
            <div class="swiper-wrapper">
              <div
                :key="item.url"
                class="swiper-slide"
                v-for="(item) in currentDirector.photo">
                <img class="swiper-slide-image" :src="getPreview(item)" />
              </div>
            </div>
          </div>
          <Pagination :swiper="photoSwiper" v-if="currentDirector.photo.length > 1" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import '@/assets/sass/directorship.sass';
import config from '@/config';
import { mapState } from 'vuex';
import marked from 'marked';
import Swiper from 'swiper';
import Pagination from '@/components/Pagination.vue';
import Loader from '@/components/Loader.vue';
import { getPreviewUrl } from '@/helpers';

export default {
  name: 'Directorship',
  components: { Loader, Pagination },
  data() {
    return {
      marked,
      currentIndex: 0,
      isLoading: true,
      directorsSwiper: null,
      photoSwiper: null,
    };
  },
  computed: {
    ...mapState({
      directorsList: (state) => state.directorship.directorsList,
    }),
    API_URL() {
      return config.API_URL;
    },
    currentDirector() {
      const data = this.directorsList[this.currentIndex];
      this.$emit('setLastCrumb', data.name);
      return data;
    },
  },
  watch: {
    currentIndex() {
      if (!this.photoSwiper) return;
      this.$nextTick(() => {
        this.photoSwiper.slideTo(0, 0);
        this.photoSwiper.update();
      });
    },
  },
  methods: {
    loadData() {
      this.$store.dispatch('getDirectorsList').then(() => {
        this.isLoading = false;
        this.$nextTick(() => {
          this.photoSwiper = new Swiper('.photo-swiper', {
            autoHeight: true,
            spaceBetween: 33,
          });

          this.directorsSwiper = new Swiper('.directors-swiper', {
            spaceBetween: 33,
            slidesPerView: 'auto',
            centeredSlides: true,
            slideToClickedSlide: true,
            on: {
              activeIndexChange: (data) => {
                this.currentIndex = data.activeIndex;
              },
            },
          });
        });
      });
    },
    getPreview(data) {
      return getPreviewUrl(data, true);
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>
